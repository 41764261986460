<template>
  <div>
    <div
      class="slider product-slider pb-10"
      :class="this.cols == 'auto' ? 'slider-auto' : 'product-grid'"
      v-if="products && products.length > 0"
      v-intersect.once="handleView"
    >
      <slot><h2 v-html="title"></h2></slot>
      <swiper :options="swiperOption" ref="swiperRef">
        <swiper-slide v-if="hasFirstCardSlot" class="first-card-swiper-slide">
          <slot name="firstCard" class="firstCard" />
        </swiper-slide>
        <swiper-slide v-for="product in products" :key="product.productId">
          <ProductCard :product="product" />
        </swiper-slide>
      </swiper>
      <div
        v-if="showBullets && products.length > 1"
        :id="`product-list-pagination-${paginationClass}`"
        class="swiper-pagination"
        @click="startAutoplay"
      ></div>
      <div
        v-if="showArrows && products.length > 1"
        :id="`product-list-slider-prev-${paginationClass}`"
        class="swiper-button-prev"
        @click="startAutoplay"
      ></div>
      <div
        v-if="showArrows && products.length > 1"
        :id="`product-list-slider-next-${paginationClass}`"
        class="swiper-button-next"
        @click="startAutoplay"
      ></div>
    </div>
    <div v-if="showMore" class="text-right show-more">
      <v-btn
        link
        text
        class="primary--text text-none "
        :to="{
          name: 'Category',
          params: { pathMatch: proposal.slug }
        }"
      >
        mostra tutti <v-icon>$arrowForward</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<style global lang="scss">
.show-more {
  .v-btn {
    margin-right: -4px;
  }
}
.product-slider {
  .proposal-img {
    margin-bottom: 10px;
  }
  .banner-card {
    width: 100%;
    margin-bottom: 20px;
  }
  .proposal-card {
    width: 220px;
    height: 365px;
    .v-card__text {
      width: auto;
    }
    img {
      padding: 0px;
      align-self: center;
    }
  }
  h2 {
    margin-bottom: 10px;
    font-size: 28px;
    letter-spacing: 0.45px;
    color: #3c3c3c;
    font-weight: normal;
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    .product-card {
      .v-image {
        min-height: 110px;
      }
    }
    .proposal-card {
      height: 285px;
      width: 170px;
      .v-card__text {
        font-size: 11px;
        padding: 0 5px;
      }
    }
    .product {
      .promo-box {
        font-size: 0.8rem;
      }
    }
  }
  .firstCard {
    float: left;
  }
}
</style>
<script>
import ProductService from "~/service/productService";
import analyticsService from "~/service/analyticsService";

export default {
  name: "ProductListSlider",
  props: {
    productList: { type: Array, required: false },
    categoryId: { type: Number, required: false },
    productId: { type: Number, required: false },
    layout: { type: Number, required: false },
    shuffle: { type: Boolean, required: false, default: false },
    sort: { type: String, required: false },
    promo: { type: Boolean, required: false, default: false },
    limit: { type: Number, required: false, default: 12 },
    title: { type: String, required: false },
    proposal: { type: Object, required: false },
    paginationClass: { type: String, required: false },
    cols: { default: 1 },
    sm: { default: 2 },
    md: { default: 3 },
    lg: { default: 4 },
    xl: { default: 6 },
    showArrows: { type: Boolean, default: true },
    showBullets: { type: Boolean, default: true }
  },
  components: {
    ProductCard: () => import("@/components/product/ProductCard.vue")
  },
  data() {
    return {
      products: [],
      showMore: false,
      swiperOption: {
        slidesPerGroup: 1,
        spaceBetween: 20,
        watchOverflow: true,
        autoplay: false,
        pagination: {
          el: `#product-list-pagination-${this.paginationClass}`,
          clickable: true
        },
        navigation: {
          prevEl: `#product-list-slider-prev-${this.paginationClass}`,
          nextEl: `#product-list-slider-next-${this.paginationClass}`
        },
        breakpoints: {
          0: {
            slidesPerView: this.cols
          },
          600: {
            slidesPerView: this.sm
          },
          960: {
            slidesPerView: this.md
          },
          1264: {
            slidesPerView: this.lg
          },
          1900: {
            slidesPerView: this.xl
          }
        }
      }
    };
  },
  async created() {
    // fetch the data when the view is created and the data is
    // already being observed
    await this.fetchProducts();
    if (!this.products || this.products.length < 1) {
      this.$emit("emptyslider");
    }
  },
  computed: {
    hasFirstCardSlot() {
      return !!this.$slots.firstCard;
    }
  },
  methods: {
    handleView(entries, observer, isIntersecting) {
      if (isIntersecting && this.proposal?.length > 0) {
        analyticsService.viewPromotions(
          [this.proposal],
          this.position,
          this.products
        );
      }
    },
    stopAutoplay() {
      this.$refs.swiperRef.swiperInstance.autoplay.stop();
    },
    startAutoplay() {
      if (!this.$refs.swiperRef.swiperInstance.autoplay.running) {
        this.$refs.swiperRef.swiperInstance.autoplay.start();
      }
    },
    async fetchProducts() {
      this.products = [];
      if (this.productList) {
        this.products = this.productList;
      } else if (this.proposal) {
        let response = await ProductService.search({
          parent_category_id: this.proposal.categoryProposedId,
          page_size: this.limit,
          sort: this.sort
        });
        this.products = response.products;
        this.showMore = response.page.totItems > response.page.itemsPerPage;
      } else {
        let response = await ProductService.adv({
          layout: this.layout,
          product_id: this.productId,
          category_id: this.categoryId,
          limit: this.limit,
          shuffle: this.shuffle,
          promo: this.promo
        });
        if (response && response.products) {
          this.products = response.products.filter(prod => prod.available > 0);
        }
        this.$emit("loaded", this.products ? this.products.length : 0);
      }
    }
  }
};
</script>
